import { Menu } from 'antd';
import classNames from 'classnames';
import useToggleSideNav from 'hooks/useToggleSideNav';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

const { SubMenu } = Menu;

export default function SideNav(props: any) {
  const { collapsed } = useToggleSideNav();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');
  const history = useHistory();

  const routes = props.menu;
  useEffect(() => {
    routes.forEach((route: any) => {
      if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute: any) => {
          if (location.pathname.startsWith(childRoute.url || '###')) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      // style={{ width: collapsed ? 80 : 250, transition: 'width 0.3s' }}
    >
      <Link className={styles.logo} to="/index">
        HOME
      </Link>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[selectedKey?.split('.')[0]]}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        {routes.map((route: any) => {
          if (route.children && route.enable) {
            return (
              <SubMenu
                key={route.key}
                title={route.text}
                icon={<img src={route.icon} alt="" />}
                onTitleClick={(e: any) => history.push(route.url)}
              >
                {route.children?.map((childRoute: any) =>
                  childRoute?.enable ? (
                    <Menu.Item key={childRoute.key}>
                      <Link to={childRoute.url}>{childRoute.text}</Link>
                    </Menu.Item>
                  ) : (
                    <></>
                  )
                )}
              </SubMenu>
            );
          }
          return route?.enable ? (
            <Menu.Item key={route.key} icon={<img src={route.icon} alt="" />}>
              <Link to={route.url}>{route.text}</Link>
            </Menu.Item>
          ) : (
            <></>
          );
        })}
      </Menu>
    </div>
  );
}
