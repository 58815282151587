import React, { Suspense, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from './wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';
import { ConfigProvider, message } from 'antd';
import CustomRenderEmpty from 'components/CustomRenderEmpty';

export const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
      retry: false,
    },
  },
});

message.config({
  // top: 100,
  duration: 2,
  maxCount: 1,
  // rtl: true,
  // prefixCls: 'my-message',
});

function App() {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://stg.static.mul-pay.jp/ext/js/token.js';
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Suspense fallback={null}>
          <ConfigProvider renderEmpty={CustomRenderEmpty}>
            <RootWrapper />
          </ConfigProvider>
        </Suspense>
      </Router>
      {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default App;
