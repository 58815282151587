import { message } from 'antd';
import { getListNotification, getTrendingSearch, readAllNotification, readNotification } from 'api/user/trendingSearch';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

export function useGetTrendingSearch(params: any) {
  const res: any = useQuery(['user_trendingSearch', params], () => getTrendingSearch(params), {
    keepPreviousData: true,
  });
  return res;
}
export function useGetListNotification(isAuthenticated: boolean) {
  const res: any = useQuery(['user_listNotification'], () => getListNotification(), {
    keepPreviousData: true,
    enabled: isAuthenticated,
  });
  return res;
}
export function useReadNotification(id: any) {
  const queryClient = useQueryClient();
  const res: any = useQuery(['user_readNotification'], () => readNotification(id), {
    keepPreviousData: true,
    enabled: !!id,
    onSuccess: () => {
      queryClient.invalidateQueries('user_listNotification');
    },
  });
  return res;
}
export function useReadAllListNotification(isReadAllNoti: boolean) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const res: any = useQuery(['user_readAllNotification'], () => readAllNotification(), {
    keepPreviousData: true,
    enabled: isReadAllNoti,
    onSuccess: () => {
      queryClient.invalidateQueries('user_listNotification');
      message.success(t('message.success'));
    },
  });
  return res;
}
