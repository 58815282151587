import { message } from 'antd';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { history } from '../App';
import configs from '../config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);
const axiosInstance = Axios.create({
  timeout: 60 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    let token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    const roleUser = Cookies.get('roleUser');
    if (roleUser && !token && !refreshToken) {
      Cookies.remove('roleUser');
      logout();
      !history?.location?.pathname?.includes('forgot-password') &&
        message.error('タイムアウトしました。再度ログインお願いします。');
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (!token && refreshToken) {
      const result = await Axios.post(`${configs.API_DOMAIN}/auth/refresh-token`, {
        refreshToken,
      });
      Cookies.set('token', result?.data?.token?.accessToken, {
        expires: result?.data?.token?.expiresIn / 86400,
      });
      if (result?.data?.token?.accessToken) {
        config.headers.Authorization = `Bearer ${result.data.token.accessToken}`;
      }
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const logout = () => {
  let recentVideo: any = localStorage.getItem('recentVideo');
  Cookies.remove('roleUser');
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('tempData');
  localStorage.clear();
  localStorage.setItem('recentVideo', recentVideo);
  if (!history?.location?.pathname?.includes('forgot-password')) {
    if (history?.location?.pathname?.includes('admin')) history.push('/admin');
    else history.push('/');
  }
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: any) => {
    const originalConfig = error.config;
    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }
    const refreshToken = Cookies.get('refreshToken');
    if (!refreshToken) {
      logout();
      !history?.location?.pathname?.includes('forgot-password') &&
        message.error('タイムアウトしました。再度ログインお願いします。');
      return Promise.reject(error);
    }
    return Axios.post(`${configs.API_DOMAIN}/auth/refresh-token`, {
      refreshToken,
    })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          Cookies.set('token', data?.token?.accessToken, {
            expires: data?.token?.expiresIn,
          });
          originalConfig.headers.Authorization = `Bearer ${data?.token?.accessToken}`;
          return Axios(originalConfig);
        } else {
          message.error('タイムアウトしました。再度ログインお願いします。');
          logout();
          return Promise.reject(error);
        }
      })
      .catch(() => {
        message.error('タイムアウトしました。再度ログインお願いします。');
        logout();
        return Promise.reject(error);
      });
  }
);

export const sendGet = (url: string, params?: any) =>
  axiosInstance.get(url, { params: { ...params, timeZone: dayjs.tz.guess() } }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance.post(url, params, { params: { ...queryParams, timeZone: dayjs.tz.guess() } }).then((res) => res.data);
export const sendPut = (url: string, params?: any) =>
  axiosInstance.put(url, params, { params: { timeZone: dayjs.tz.guess() } }).then((res) => res.data);
export const sendPatch = (url: string, params?: any) =>
  axiosInstance.patch(url, params, { params: { timeZone: dayjs.tz.guess() } }).then((res) => res.data);
export const sendDeleteBody = (url: string, params?: any) =>
  axiosInstance({ url, method: 'DELETE', data: { ...params } }).then((res) => res.data);
export const sendDelete = (url: string, params?: any) =>
  axiosInstance.delete(url, { params: { ...params } }).then((res) => res.data);
