const configs: any = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  SHOP_ID: process.env.REACT_APP_SHOP_ID,
  CLIENTID: process.env.REACT_APP_CLIENTID,
  CREATOMATE_API_DOMAIN: process.env.REACT_APP_CREATOMATE_API_DOMAIN,
  CREATOMATE_API_ID: process.env.REACT_APP_CREATOMATE_API_ID,
  CHATGPT_API_ID: process.env.REACT_APP_CHAT_GPT_KEY,
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID
};

export default configs;
