import dayjs from 'dayjs';

export function genTimeDetailChart(date: number, interval = 'week'): string {
  const year = dayjs().year();
  const month = dayjs().month() + 1;
  if (interval === 'month') {
    return `${date}`;
  } else if (interval === 'year') {
    return date >= 10 ? `${year}/${date}` : `${year}/0${date}`;
  } else if (interval === 'day') {
    return `${date}h`;
  }
  return `${year}/${month}/${date}`;
}
