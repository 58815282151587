import { message } from 'antd';
import { withdrawalCreator } from 'api/creator/info';
import { getInfo, updateInfo, withdrawalUser } from 'api/profile';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export default function useProfile() {
  const { data: profile }: any = useQuery('profile');
  return { profile };
}

export function useGetInfo(isAuthenticated?: boolean) {
  const history = useHistory();
  const res: any = useQuery('user_me', async () => isAuthenticated && (await getInfo()), {
    keepPreviousData: false,
    enabled: isAuthenticated,
    // onError: () => {
      // Cookies.remove('token');
      // Cookies.remove('refreshToken');
      // Cookies.remove('tempData');
      // localStorage.clear();
      // if (history.location.pathname?.includes('admin')) history.push('/admin');
      // else history.push('/');
    // },
  });
  return res;
}
export const useUpdateInfo = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(async (payload: any) => await updateInfo(payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('user_me');
      message.success(t('message.updateSuccess'));
    },
    onError: (error: any) => {
      message.error(error.response.data.message);
    },
  });
};
export const useWithdrawalUser = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(async () => await withdrawalUser(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('user_me');
      message.success(t('message.updateSuccess'));
    },
    onError: (error: any) => {
      message.error(error.response.data.message);
    },
  });
};
export const useWithdrawalCreator = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(async () => await withdrawalCreator(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('user_me');
      message.success(t('message.updateSuccess'));
    },
    onError: (error: any) => {
      message.error(error.response.data.message);
    },
  });
};
