import dayjs from 'dayjs';
export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum PAGINATION {
  START = 1,
  PAGE_SIZE = 6,
  PAGE_SIZE_MIN = 10,
  PAGE_SIZE_MAX = 100000,
}

export const DATEFORMAT = 'YYYY-MM-DD';
export const DATEFORMAT2 = 'YYYY/MM/DD';
export const DATEFORMAT3 = 'YYYY/MM';
export const DATEFORMAT4 = 'DD/MM/YYYY';
export const DATEFORMAT5 = 'MM/YYYY';
export const DATEFORMAT6 = 'YY/MM';
export const DATEFORMAT7 = 'MM';
export const DATEFORMAT_JP = 'YYYY年MM月DD日';
export const TIMEFORMAT = 'HH:mm';
export const TIMEFORMATNOTI = 'HH:mm - YYYY/MM/DD';

export const STATUS_VIDEO = [
  {
    en: 'WAITING REVIEW',
    ja: '承認待ち',
  },
  {
    en: 'IN REVIEW',
    ja: '確認中',
  },
  {
    en: 'TIMER',
    ja: '期間限定',
  },
  {
    en: 'REJECTED',
    ja: '却下',
  },
  {
    en: 'SALE',
    ja: '販売中',
  },
  {
    en: 'STOP',
    ja: '停止中',
  },
  {
    en: 'FORBIDDEN',
    ja: '販売禁止',
  },
];
export const STATUS_VIDEO_SELLER = [
  {
    en: 'IN REVIEW',
    ja: '確認中',
  },
  {
    en: 'SALE',
    ja: '販売中',
  },
  {
    en: 'WAITING REVIEW',
    ja: '承認待ち',
  },
  {
    en: 'STOP',
    ja: '停止中',
  },
  {
    en: 'REJECTED',
    ja: '却下',
  },
  {
    en: 'TIMER',
    ja: '期間限定',
  },
  {
    en: 'FORBIDDEN',
    ja: '販売禁止',
  },
];

export const STATUS_CREATOR = [
  {
    en: 'Active',
    ja: '有効',
  },
  {
    en: 'Withdrawal',
    ja: '自主退会',
  },
  {
    en: 'Block',
    ja: '強制退会',
  },
];

export const STATUS_USER = [
  {
    en: 'Active',
    ja: '有効',
  },
  // {
  //   en: 'Pending',
  //   ja: '保留中',
  // },
  {
    en: 'Canceled',
    ja: '自主退会',
  },
  {
    en: 'Block',
    ja: '強制退会',
  },
];

export const STATUS_CONTACT = [
  {
    en: 'All',
    ja: '全て',
  },
  {
    en: 'Replied',
    ja: '返信済み',
  },
  {
    en: 'Unreplied',
    ja: '未返信',
  },
];

export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum CONTACT_STATUS {
  All = 'All',
  Replied = 'Replied',
  Unreplied = 'Unreplied',
}

export enum CONTACT_STATUS_ADMIN {
  Replied = 'Replied',
  Unreplied = 'Unreplied',
}

export enum USER_STATUS {
  Active = 'Active',
  Canceled = 'Canceled',
  Block = 'Block',
}

export enum TYPE_VIDEO {
  PREMIUM = 'プレミアム',
  REGULAR = 'レギュラー',
}

export const MASTER_VIDEO = [
  {
    id: 1,
    title: 'Model',
    link: '/admin/master/model',
  },
  {
    id: 2,
    title: 'FrameRate',
    link: '/admin/master/fps',
  },
  {
    id: 3,
    title: 'Duration',
    link: '/admin/master/duration',
  },
  {
    id: 4,
    title: 'Category',
    link: '/admin/master/category',
  },
  {
    id: 5,
    title: 'Tag',
    link: '/admin/master/tag',
  },
  {
    id: 6,
    title: 'Situation',
    link: '/admin/master/situation',
  },
  {
    id: 7,
    title: 'Resolution',
    link: '/admin/master/resolution',
  },
  {
    id: 8,
    title: 'ShotAngle',
    link: '/admin/master/shotAngle',
  },
];

export enum PACKAGE_TYPE {
  INDIVIDUAL = 'Personal',
  CORPORATION = 'Company',
  ENTERPRISE = 'Enterprise',
}

export const PACKAGE_TYPE_CHECK = [
  {
    en: 'FreeUser',
    ja: '不問',
  },
  {
    en: 'Personal',
    ja: '個人',
  },
  {
    en: 'Company',
    ja: '法人',
  },
  {
    en: 'Enterprise',
    ja: 'エンタープライズ',
  },
];

export const TYPE_MASTER = [
  {
    en: 'Model',
    ja: 'モデル',
  },
  {
    en: 'FrameRate',
    ja: 'フレームレート',
  },
  {
    en: 'Duration',
    ja: 'デュレーション',
  },
  {
    en: 'Category',
    ja: 'カテゴリー',
  },
  {
    en: 'Tag',
    ja: 'タグ',
  },
  {
    en: 'Situation',
    ja: 'シチュエーション',
  },
  {
    en: 'Resolution',
    ja: '解像度',
  },
  {
    en: 'ShotAngle',
    ja: '撮影機材',
  },
];

export const MEMBER_TYPE = [
  {
    en: 'FreeUser',
    ja: '未決済者',
  },
  {
    en: 'PackageOwner',
    ja: '主契約者',
  },
  {
    en: 'Member',
    ja: '招待者',
  },
];

export const STAFF_ROLE = [
  {
    en: 'Admin',
    ja: '管理者',
  },
  {
    en: 'GeneralModerator',
    ja: 'サブ管理者',
  },
  {
    en: 'FinancialManager',
    ja: '売上や金銭に関する権限',
  },
  {
    en: 'CustomerRelationManager',
    ja: '顧客対応に関する権限',
  },
  {
    en: 'MaterialModerator',
    ja: '動画関係の権限',
  },
  {
    en: 'VideoInspector',
    ja: '動画の確認のみ',
  },
  {
    en: 'Attorney',
    ja: '弁護士の権限',
  },
];

export enum PACKAGE_SCOPE {
  PUBLIC = 1,
  PRIVATE = 2,
}

export enum STATUS_BANNER {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum REVIEW_VIDEO_TYPE {
  IN_REVIEW = 'IN REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  STOP = 'STOP',
  FORBIDDEN = 'FORBIDDEN',
  DELETE = 'DELETE',
}

export enum POINT_STATUS {
  SELLING = 'Selling',
  STOP = 'Stop',
}

export enum LIMIT_NUMBER_INDIVIDUAL {
  LIMIT_NUMBER_INDIVIDUAL = 1,
}

export enum PERCENT {
  MIN = 0,
  MAX = 100,
}

export enum CAMPAIGN_TYPE {
  POINT = 'Point',
  VOUCHER = 'Voucher',
}

export enum MEMBER_TYPE_FILTER_USER {
  USER = 'User',
  ORGANIZOR = 'Organizor',
}

export enum MEMBER_STATUS {
  ACTIVE = 'Active',
  BLOCK = 'Block',
}

export enum PACKAGE_FILTER_USER {
  FAMILY = 'Company',
  PERSONAL = 'Personal',
  ENTERPRISE = 'Enterprise',
  NO = 'FreeUser',
}

export const PREFECTURES = [
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' },
];

export enum KEY_TAB_LIST_FILTER_USER {
  SELECTLIST = '1',
  LISTAPPLY = '2',
}

export const PLAN_TYPES: any = [
  {
    id: PACKAGE_TYPE.INDIVIDUAL,
    title: '個人',
  },
  {
    id: PACKAGE_TYPE.CORPORATION,
    title: '法人',
  },
  {
    id: PACKAGE_TYPE.ENTERPRISE,
    title: 'エンタープライズ',
  },
];

export const SEARCH_DASHBOARD: any = [
  {
    id: 'Today',
    title: '本日',
  },
  {
    id: 'Last7Days',
    title: '本日を含む過去7日間',
  },
  {
    id: 'ThisMonth',
    title: '今月',
  },
  {
    id: 'All',
    title: '全て',
  },
];

export const SEARCH_DASHBOARD_RANKING: any = [
  {
    id: 'Last7Days',
    title: '本日を含む過去7日間',
  },
  {
    id: 'ThisMonth',
    title: '今月',
  },
  {
    id: 'All',
    title: '全て',
  },
];
export enum PackageOptionType {
  Month = 'Month',
  Year = 'Year',
}
export enum PurchaseType {
  GMO = 'GMO',
  PayPal = 'PayPal',
  Other = 'Other',
}
export const DASHBOARD_RANKING: any = [
  {
    id: 'Today',
    title: '本日',
  },
  {
    id: 'Last7Days',
    title: '本日を含む過去7日間',
  },
  {
    id: 'ThisMonth',
    title: '今月',
  },
  {
    id: 'All',
    title: '全て',
  },
];
export const DASHBOARD_RANKING2: any = [
  {
    id: 'Last7Days',
    title: '本日を含む過去7日間',
  },
  {
    id: 'ThisMonth',
    title: '今月',
  },
  {
    id: 'All',
    title: '全て',
  },
];
export enum RangeDateType {
  Today = 'Today',
  Last7Days = 'Last7Days',
  ThisMonth = 'ThisMonth',
  All = 'All',
}

export enum RANKING_TYPE {
  Videos = 'Videos',
  Categories = 'Categories',
  Creators = 'Creators',
}
export enum CARD {
  Visa = 'Visa',
  MASTER = 'Master',
  JCB = 'JCB',
  AMEX = 'Amex',
  DINERS = 'Diners',
}
export enum COST_STATUS {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}
export enum PURCHASE_STATUS {
  Success = 'Success',
  Fail = 'Fail',
}
export enum TYPE_CATEGORY {
  CTG1 = 'CTG1',
  CTG2 = 'CTG2',
  CTG3 = 'CTG3',
}
export const modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const formats = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const FILE_CHUNK_SIZE = (size: number) => {
  const MB = 2 ** 20;
  if (size <= 500 * MB) {
    return 5 * MB;
  } else if (size <= 1200 * MB) {
    return 10 * MB;
  }
  return 25 * MB;
};

export enum UPLOADFILETYPE {
  PREVIEW = 'PREVIEW',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
  GIF = 'GIF',
}
export const MONTH_IN_YEAR = Array.from({ length: 12 }, (e, i) => ({
  name: i < 9 ? `0${i + 1}` : i + 1,
  value: i < 9 ? `0${i + 1}` : i + 1,
}));

export const DAY_IN_MONTH = Array.from({ length: 31 }, (e, i) => ({
  name: i < 9 ? `0${i + 1}` : i + 1,
  value: i < 9 ? `0${i + 1}` : i + 1,
}));

export const LIST_YEAR = Array.from({ length: dayjs().year() - 1900 + 1 }, (e, i) => ({
  name: i + 1900,
  value: i + 1900,
}));

export enum MasterDataType {
  oldType = 'oldType',
  newType = 'newType',
}
