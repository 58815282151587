import { Button, Col, Divider, Input, Row, Select, Tooltip, message } from 'antd';
import { CopyOutlined, FacebookFilled, InstagramFilled, TwitterCircleFilled, DeleteOutlined } from '@ant-design/icons';
import React, { useState, PureComponent, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { reportSocialTracking } from 'api/user/tracking';
import dayjs from 'dayjs';
import { genTimeDetailChart } from './util';

function GenLinkSocials() {
  const [links, setLinks] = useState({
    facebook: '',
    instagram: '',
    twitter: '',
  });

  function getLink(params: string) {
    const domain = window.location.hostname;
    setLinks({
      ...links,
      [params]: `${domain}/landing/${params}`,
    });
    message.success('Gen link success! Press to button to copy link!');
  }

  function deleteLink(params: string) {
    setLinks({
      ...links,
      [params]: '',
    });
    message.success('Delete success!!');
  }

  function copyLink(params: string) {
    navigator.clipboard.writeText(params);
    message.success('Copied!');
  }

  function saveLink() {
    // if (!links.facebook && !links.instagram && !links.twitter) {
    //   message.error('Nothing to save! Press to genlink!');
    // } else {
    sessionStorage.setItem('links', JSON.stringify(links));
    message.success('Save link success!!');
    // }
  }

  const [interval, setInterval] = useState('week');
  const handleChangeTime = (value: any) => {
    setInterval(value);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    reportSocialTracking(interval)
      .then((res: any) => {
        // INFO: dayjs get start to sunday and end saturday with default interval is week
        let start = dayjs().startOf('weeks').get('date') + 1;
        let end = dayjs().endOf('week').get('date') + 1;
        if (interval === 'day') {
          [start, end] = [1, 23];
        } else if (interval === 'month') {
          start = dayjs().startOf('month').get('date');
          end = dayjs().endOf('month').get('date');
        } else if (interval === 'year') {
          [start, end] = [1, 12];
        }
        const arrayDate = [...Array(end - start + 1)].map((_, i) => i + start);
        // // const arraySocial = ['facebook', 'instagram', 'twitter'];
        const data = arrayDate.reduce((result: any, date: number) => {
          const trackings = res.filter((dt: any) => dt.date === date);
          if (trackings.length === +0) {
            result.push({
              name: genTimeDetailChart(date, interval),
              facebook: 0,
              instagram: 0,
              twitter: 0,
            });
          } else {
            result.push({
              name: genTimeDetailChart(date, interval),
              facebook: trackings.find((t: any) => t.social_type === 'facebook')?.total || +0,
              instagram: trackings.find((t: any) => t.social_type === 'instagram')?.total || +0,
              twitter: trackings.find((t: any) => t.social_type === 'twitter')?.total || +0,
            });
          }
          return result;
        }, []);
        setData(data);
      })
      .catch((e) => console.log(e));
  }, [interval]);

  return (
    <div>
      <div className="mx-30 pt-29 pb-21">
        <p className="text-24 font-weight-700">Socials</p>
      </div>
      <div className="mx-30 min-height-700 bg-white radius-12 px-30 py-24">
        <p className="text-18 font-weight-700">Cross-platform</p>
        <Divider />
        <div>
          <Row gutter={[8, 8]}>
            <Col xs={12}>
              <Input.Group compact>
                <Input
                  addonBefore="Facebook"
                  style={{ width: '80%' }}
                  placeholder="Click the button to get link or copy..."
                  value={links.facebook}
                  readOnly
                />
                {links.facebook ? (
                  <>
                    <Tooltip title="Copy">
                      <Button icon={<CopyOutlined className="color-copy" />} onClick={() => copyLink(links.facebook)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button icon={<DeleteOutlined className="color-red" />} onClick={() => deleteLink('facebook')} />
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Get link">
                    <Button icon={<FacebookFilled className="color-fb" />} onClick={() => getLink('facebook')} />
                  </Tooltip>
                )}
              </Input.Group>
            </Col>
            <Col xs={12}>
              <Input.Group compact>
                <Input
                  addonBefore="Twitter"
                  style={{ width: '80%' }}
                  placeholder="Click the button to get link or copy..."
                  value={links.twitter}
                  readOnly
                />
                {links.twitter ? (
                  <>
                    <Tooltip title="Copy">
                      <Button icon={<CopyOutlined className="color-copy" />} onClick={() => copyLink(links.twitter)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button icon={<DeleteOutlined className="color-red" />} onClick={() => deleteLink('twitter')} />
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Get link">
                    <Button icon={<TwitterCircleFilled className="color-black" />} onClick={() => getLink('twitter')} />
                  </Tooltip>
                )}
              </Input.Group>
            </Col>
            <Col xs={12}>
              <Input.Group compact>
                <Input
                  addonBefore="Instagram"
                  style={{ width: '80%' }}
                  placeholder="Click the button to get link or copy..."
                  value={links.instagram}
                  readOnly
                />
                {links.instagram ? (
                  <>
                    <Tooltip title="Copy">
                      <Button
                        icon={<CopyOutlined className="color-copy" />}
                        onClick={() => copyLink(links.instagram)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button icon={<DeleteOutlined className="color-red" />} onClick={() => deleteLink('instagram')} />
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Get link">
                    <Button icon={<InstagramFilled className="color-active" />} onClick={() => getLink('instagram')} />
                  </Tooltip>
                )}
              </Input.Group>
            </Col>
            <Col xs={12}>
              <Button className="btn btn-pri" onClick={saveLink}>
                Save
              </Button>
            </Col>
          </Row>
          <Row className="mb-30">
            <Col xs={19} />
            <Select
              defaultValue="week"
              style={{ width: 120 }}
              onChange={handleChangeTime}
              options={[
                { value: 'day', label: 'Day' },
                { value: 'week', label: 'Week' },
                { value: 'month', label: 'Month' },
                { value: 'year', label: 'Year' },
              ]}
            />
          </Row>
          <Row>
            <Col xs={2} />
            {/* <ResponsiveContainer width="50%" height="5%"> */}
            {interval === 'week' ? (
              <LineChart
                width={1200}
                height={500}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name"/>
                <YAxis/>
                <ChartTooltip />
                <Legend />
                <Line type="monotone" dataKey="facebook" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="instagram" stroke="#d62976" />
                <Line type="monotone" dataKey="twitter" stroke="#82ca9d" />
              </LineChart>
            ) : (
              <BarChart
                width={1200}
                height={500}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <ChartTooltip />
                <Legend />
                <Bar type="monotone" dataKey="facebook" fill="#8884d8" />
                <Bar type="monotone" dataKey="instagram" fill="#d62976" />
                <Bar type="monotone" dataKey="twitter" fill="#82ca9d" />
              </BarChart>
            )}
            {/* </ResponsiveContainer> */}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default GenLinkSocials;
