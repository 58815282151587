/**
 * @file routes/routeLoader.ts
 * @description lazy loading of routes.
 *
 * @author ogarcia (ozkary)
 *
 */

/**
 * Use this route loader when using lazy loading and dynamic imports
 * @param factory function for dynamic load of routes
 * @param maxRetry number of retries
 * @returns a page container
 */
 const routeLoader = <T>(factory: () => Promise<T>, maxRetry = 3): Promise<T> => {
  return new Promise((resolve, reject) => {
    factory()
      .then(resolve)
      .catch((error) => {
        if (maxRetry === 0) {
          reject(error);
          // this is probably a white page error. reload the main page
          // alt - add better messaging or load another page
          window.location.reload();
          return;
        }

        // recursively try again reducing the retry count
        routeLoader(factory, --maxRetry).then(resolve, reject);
      });
  });
};

export default routeLoader;