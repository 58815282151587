import { Empty } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const RenderEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.content}>
      {Empty.PRESENTED_IMAGE_SIMPLE}
      <div className="color-white">{t('emptyText')}</div>
    </div>
  );
};

const CustomRenderEmpty = () => <RenderEmpty />;
export default CustomRenderEmpty;
